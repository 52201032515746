<template>
  <footer>
    <article>
      © 2024 Align Technologies. All Rights Reserved. 800.676.4034
    </article>
    <article></article>
  </footer>
</template>

<script>
export default {
  name: 'TwFooter'
}
</script>
<style scoped>
footer {
  display: block;
  margin-top: 40px;
  font-size: 12px;
  padding: 12px 24px;
  border-top: solid thin currentColor;
}
@media (min-width: 1280px) {
  footer {
    text-align: right;
  }
}
</style>
